@import './color';


@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba($light-green, 0.5);
  }
  100% {
    box-shadow: 0 0 0 35px rgba($light-green, 0);
  }
}

@keyframes shadow-pulse-big
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: shadow-pulse 1s infinite;
  border-radius: 50%;
}