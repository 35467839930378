@import '../../styles/color';

#gift-gift-list-container {
  height: 100%;
}

#gift-gift-list-container .giftee-name {
  white-space: nowrap;
}

@media (max-width:435px) {
  #gift-gift-list-container .giftee-name {
    font-size: 2.5rem;
    white-space: nowrap;
  }
}

@media (max-width:370px) {
  #gift-gift-list-container .giftee-name {
    font-size: 2rem;
    white-space: nowrap;
  }
}

#gift-gift-list-container .gift-add {
  background-color: rgba($red, .2);
  padding: 25px;
  border-radius: 15px;
  border: 4px solid $green;
}

#gift-gift-list-container .gift-item {
  display: flex;
  justify-content: space-between;
  border: 2px solid $dark-red;
  border-radius: 15px;
}

#gift-gift-list-container .gift-item .edit-options {
  display: flex;
  flex-direction: column;
}

#gift-gift-list-container .gift-item .edit-options button {
  padding: 15px;
}

#gift-gift-list-container .gift-item .edit-options .button {
  color: $off-white;
  flex-grow: 1;
}

#gift-gift-list-container .gift-item .edit-options .button.green {
  border: 1px solid rgba($light-green, 0.5);
  background-color: $green;
}

#gift-gift-list-container .gift-item .edit-options .button.green:hover {
  background-color: $light-green;
}

#gift-gift-list-container .gift-item .edit-options .button.red {
  border: 1px solid rgba($red, 0.5);
  background-color: $dark-red;
}

#gift-gift-list-container .gift-item .edit-options .button.red:hover {
  background-color: $red;
}

#gift-gift-list-container .gift-item .edit-options .button.top {
  border-top-right-radius: 14px;
}

#gift-gift-list-container .gift-item .edit-options .button.bottom {
  border-bottom-right-radius: 14px;
}

#gift-gift-list-container .gift-item .information {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  overflow: hidden;
}

#gift-gift-list-container .gift-item .information .description {
  background-color: rgba($red, 0.2);
  padding: 25px;
  flex-grow: 2;
  border-bottom: 1px solid rgba($red, 0.5);
  border-right: 1px solid rgba($red, 0.5);
  border-top-left-radius: 10px;
}

#gift-gift-list-container .gift-item .information .description span {
  font-weight: 300;
  font-size: 1.5rem;
  overflow-wrap: break-word;
}


#gift-gift-list-container .gift-item .information .helper {
  background-color: rgba($off-white, 0.8);
  padding: 10px 25px;
  flex-grow: 2;
  border-bottom-left-radius: 15px;
  border-right: 1px solid rgba($red, 0.5);
}

#gift-gift-list-container .gift-item .information .helper span {
  font-weight: 400;
  font-size: 0.8rem;
  overflow-wrap: break-word;
}

#gift-gift-list-container a:hover {
  color: $green;
  text-decoration: none;
  background-color: rgba($red, 0.25);

}

#gift-gift-list-continer .react_tinylink_card, .ecIkKa {
  max-width: none !important;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgba($green, 0.0);
}