@import '../../styles/color';

#gift-navbar {
  background-color: $dark-red;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: $off-white;
  z-index: 1000;
  border-bottom: 1px solid $red;
}

#gift-navbar .nav-link {
  color: $off-white;
}

#gift-navbar img {
  border: 1px solid $red;
  border-radius: 20px;
  padding: 3px;
  margin-right: 15px;
  background-color: #fff;
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 768px) {

  #gift-navbar .active {
    border-left: 3px solid $green;
    padding-left: 15px !important;
    border-bottom: 0px !important;
    font-weight: 700;
  }
}

#gift-navbar .active {
  color: $off-white !important;
  border-bottom: 3px solid $off-white;
}

#gift-navbar .dropdown-item:hover {
  background-color: $light-green;
}

#userButton img {
  border: $light-green 2px solid;
  background: transparent;
  margin: 0 10px 0 0;
  padding: 0;
  height: 25px;
  width: 25px;
}

#loginButton img {
  border: $light-green 2px solid;
  background: transparent;
  margin: 0 0 0 10px;
  padding: 0;
  height: 25px;
  width: 25px;
}
