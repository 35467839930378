@import './color';

.italic {
  font-style: italic;
}

.normal {
  font-style: normal;
}

.text-red {
  color: $red;
}

.text-dark-red {
  color: $dark-red;
}

.text-light-green {
  color: $light-green;
}

.text-green {
  color: $green;
}

.text-light-silver {
  color: $light-silver;
}

.text-white {
  color: $white;
}

.text-off-white {
  color: $off-white;
}