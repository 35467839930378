@import './color';

button {
  outline: none
}

.btn-link {
  border-radius: 0 !important;
}

.btn-gift-green {
    color: #fff;
    background-color: $green;
    border-color: $light-green;
}

.btn-gift-green:hover {
  background-color: $light-green;
}

.btn-gift-red {
    color: #fff;
    background-color: $red;
    border-color: $light-silver;
}

.btn-gift-red:hover {
  background-color: $light-silver;
}

.btn-gift-red-dark {
  color: #fff;
  background-color: $dark-red;
  border-color: $light-silver;
}

.btn-gift-red-dark:hover {
  color: $light-silver;
  background-color: $red;
}
