@import '../../styles/color';

#version-container {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba($red, 0.1);
}

#version-container span {
  color: $off-white
}