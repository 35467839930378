@import url('https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800,900&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
@import './color';
@import './buttons';
@import './text';
@import './animations';

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('../images/background-christmas-gifts-2.jpg') no-repeat center center fixed;
  background-size: cover;
}

a {
  color: $green;
}

a:hover {
  color: $light-green;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 3em;
  font-weight: 300;
  color: $dark-red;
}

.Toastify__toast {
  font-family: "Work Sans", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none;
}